import React, {useEffect, useState } from "react";
import Header from '../Common/Header';
import Footer from '../Common/Footer';


function About() {



  return (
        <>
          <Header />
          <div className="wrapper">
          <div className="bradcumb-area style-about">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col">
						
							{/* <div className="bradcumb text-center">
								<h3>About Nimas</h3>
							</div>
						 */}
						</div>
					</div>
				</div>
			</div>

          </div>

          <div className="about-adventure-area pt-110 pb-200">
				<div className="container">
					
					<div className="row justify-content-center">
						<div className="col-lg-10">
							<p className="text-center mb-45 wow fadeInDown">
                            Nestled within a sprawling 38-acre contemporary campus, NIMAS stands as the
premier adventure institute, boasting expertise in land, air, and aqua verticals. Our
state-of-the-art facilities and world-class trainers offer unparalleled professional
training in mountaineering and adventure sports.
 </p>
							
							<p className="text-center mb-45 wow fadeInDown">
							Embark on a journey with us as we delve into the realms of mountaineering, mountain terrain biking, scuba diving, white water rafting, paragliding, and paramotor training
							</p>

                            
							<p className="text-center mb-45 wow fadeInDown">
							With a rich history of shaping aspiring adventurers, NIMAS has honed the skills of
thousands of students from across the country, cultivating a legacy of excellence in
mountaineering and beyond. Join us and become a part of this extraordinary tradition today
							</p>
						</div>
					</div>
					
				</div>
			</div>
          <Footer />
              
        </>
  );
}

export default About;
