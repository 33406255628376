import React, {useEffect, useState } from "react";
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import { ThreeDots } from 'react-loader-spinner'


function Downloads() {
	let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;
    const [productdata, setProductData] = useState([]);
	const [isLoader, setIsLoader] = useState(true);
	const Downloadslistpra = { "NumberOfFieldsView": 50, "filters": {}, "isDownload": false, "index": 1, "pgSize": 1000, "templateID": 10 };
	function DownloadPdf() {
   
		fetch(
		  "https://api.dialerp.com/api/api/getTemplateDataList",
		  {
			method: "POST",
			headers: {
			  "Authorization-Token": authToken,
			  Accept: "application/json, text/plain",
			  "Content-Type": "application/json;charset=UTF-8",
			},
			body: JSON.stringify(Downloadslistpra),
		  }
		).then((result) => {
		  result.json().then((resp) => {
			setProductData(resp);
			// fun_ReceiptListdata();
			console.log(resp,"DataDownload");
		  });
		  setIsLoader(false);
		});
	  }
	  useEffect(() => {
		DownloadPdf();
		
	  }, [0])
  return (
        <>
		  {isLoader && <div id="loadingg">
		<ThreeDots 
		  height="80" 
		  width="80" 
		  radius="9"
		  color="#fff" 
		  ariaLabel="three-dots-loading"
		  wrapperStyle={{}}
		  wrapperClassName=""
		  visible={true}
		  />
	  </div>}
          <Header />
          <div className="wrapper">
          <div className="bradcumb-area style-download">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col">
{/* 						
							<div className="bradcumb text-center">
								<h3>Downloads</h3>
							</div>
						 */}
						</div>
					</div>
				</div>
			</div>

          </div>

          <div className="about-adventure-area pt-110 pb-150 download">
				<div className="container">
					
					<div className="row justify-content-center">
						<div className="col-lg-12">
                        <div className="table-responsive tdown mb-30">
							    <table className="table trs">
							     
							        <tbody>
									{productdata?.response?.records?.map((studinfo, i) => {
                                  return (
							            <tr>
							                <th scope="row">
							                	<div className="product-cart-wrap wow fadeInDown">
							                	
							                		<div className="product-cart-tbl-content"> <h6>{studinfo[4].value}</h6>
							                		</div>
							                	</div>
							                </th>
                                            <td><a href={studinfo[5].value} download><i className="fas fa-download"></i></a></td>
							            </tr>
										    );
										})}
							          
							        </tbody>
							    </table>
							</div>
						</div>

                        
					</div>
					
				</div>
			</div>
          <Footer />
              
        </>
  );
}

export default Downloads;
