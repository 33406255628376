import React, {useEffect, useState } from "react";
import Header from '../Common/Header';
import Footer from '../Common/Footer';


function Faq() {


  return (
        <>
          <Header />
          <div className="wrapper">
          <div className="bradcumb-area style-faq">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col">
{/* 						
							<div className="bradcumb text-center">
								<h3>FAQ</h3>
							</div>
						 */}
						</div>
					</div>
				</div>
			</div>
      <div className="faq pb-200">

<div className="container">
<div className="row justify-content-center">
 	<div className="col-lg-12">

<div className="tabs">
  
<div className="tab wow fadeInDown">
<input type="radio" id="rd100" name="rd" />
<label className="tab-label" for="rd100">1. How to book a seat at NIMAS?</label>
<div className="tab-content">
<p>First you have to register yourself on the website, then log in with your credentials and choose from the variety of courses offered by NIMAS.  Download the application and medical form and upload them alongwith fee receipt and Aadhar Card.  You will get a confirmation or reply within a 48 working hours. 

</p>


</div>
</div>



<div className="tab wow fadeInDown">
<input type="radio" id="rd2" name="rd" />
<label className="tab-label" for="rd2">2. What is an Adventure Insurance? How can I get an Adventure Insurance? Does NIMAS provide an Adventure Insurance in the institute itself?</label>
<div className="tab-content">
<p>Insurance which covered untowards event during conduct of Adventure course is
called Adventure Insurance. Your health insurance may or may not cover adventure sports in
your policy. It is intimated that, Adventure Insurance is mandatory for all the adventure
courses. Is your policy covers adventures sports then there is no need to by us a separate
insurance. Else you have to purchase one. <b>Adventure Insurance is required for course
duration only</b>. The students are requested to get the insurance done from any
nationalized/private bank or insurance company. NIMAS does not provide any adventure
insurance. Student can buy the adventure insurance before commencement of respective
course. List of some of the Adventure insurance agencies and their mobile No are as
under</p>
<table style={{border:'1px solid #484848', width:'100%'}}>
                              <tr style={{borderBottom:'1px solid #484848'}}>
                                  <th>Sr.</th>
                                  <th>NAME OF AGENCY</th>
                                  <th>EMAIL ID</th>
                                  <th>Contact No</th>
                              </tr>
                              <tr style={{borderBottom:'1px solid #484848'}}>
                                  <td>(a)</td>
                                  <td>Adventure Sports Cover
ASC 360</td>
                                  <td>info@asc360.com</td>
                                  <td>8448765102, 8742964774</td>
                              </tr>

                              <tr style={{borderBottom:'1px solid #484848'}}>
                                  <td>(b)</td>
                                  <td>ICICI Lombard General
Insurance Company Ltd</td>
                                  <td>customersupport@icicilombard.co
m</td>
                                  <td>18002666</td>
                              </tr>

                              <tr style={{borderBottom:'1px solid #484848'}}>
                                  <td>(c)</td>
                                  <td>Bajaj Allianz General Insurance Co. Ltd</td>
                                  <td>jayesh.joshi@bajanfinserv.in</td>
                                  <td>8390180202, 18002095858</td>
                              </tr>

                              <tr style={{borderBottom:'1px solid #484848'}}>
                                  <td>(d)</td>
                                  <td>TATA AIG General
Insurance Company Ltd</td>
                                  <td>customersupport@tataaig.com</td>
                                  <td>18002660597</td>
                              </tr>
                          </table>

                          <p><b>Note:</b> Students are free to take an agency (any other than listed above) Provided the insurance
covers adventure sports for the course duration and is valid and relevant</p>

</div>
</div>
<div className="tab wow fadeInDown">
<input type="radio" id="rd3" name="rd" />
<label className="tab-label" for="rd3">3. Is it necessary to send all the documents by post to institute? </label>
<div className="tab-content">
<p>No, it is not necessary to send any documents by post to the institute. </p>

</div>
</div>

<div className="tab wow fadeInDown">
<input type="radio" id="rd44" name="rd" />
<label className="tab-label" for="rd44">4. When will I receive the Joining Instructions for the Course? </label>
<div className="tab-content">
<p>Joining instruction will be forwarded 45 days prior from the commencement of the course.  </p>
</div>
</div>

<div className="tab wow fadeInDown">
<input type="radio" id="rd434" name="rd" />
<label className="tab-label" for="rd434">5. Can my seat be transferred to next batch?
</label>
<div className="tab-content">
<p>Write an e-mail requesting for transfer and wait for confirmation,  Your seat will be transferred to desired/next batch as per availability, but the seat can only be prepone or postpone only once for the same sports.  </p>
</div>
</div>


<div className="tab wow fadeInDown">
<input type="radio" id="rd7" name="rd" />
<label className="tab-label" for="rd7">6. Which networks are accessible in the institute? </label>
<div className="tab-content">
There is following mobile network accessibility:-
<ul>
<li>Airtel.</li>
<li>Vodafone</li>
<li>Reliance Jio</li>
</ul>
</div>
</div>
<div className="tab wow fadeInDown">
<input type="radio" id="rd8" name="rd" />
<label className="tab-label" for="rd8">7. Are there any seats available for Adventure Courses this year? </label>
<div className="tab-content">
Please visit our booking site <a href="www.nimasdirang.com">www.nimasdirang.com</a> for available seats and courses.
</div>
</div>


<div className="tab wow fadeInDown">
<input type="radio" id="rd9" name="rd" />
<label className="tab-label" for="rd9">8. I don’t have a PAN card. Is a PAN card mandatory for booking in a course? </label>
<div className="tab-content">
A valid photo ID issued by GoI is mandatory for booking. You can use following ID’s
as mentioned below :
<ul>
<li>Aadhar Card</li>
<li>Voter ID</li>
<li>Passport</li>
</ul>
</div>
</div>
<div className="tab wow fadeInDown">
<input type="radio" id="rd10" name="rd" />
<label className="tab-label" for="rd10">9. I am unable to book Adventure Course. The website is not working for me? </label>
<div className="tab-content">
Please contact our booking desk Mob No +91-7640002112 during office working hours 09:00hrs to 17:00hrs for resolution of your problem 
</div>
</div>


<div className="tab wow fadeInDown">
<input type="radio" id="rd110" name="rd" />
<label className="tab-label" for="rd110">10. How to reach NIMAS from Guwahati?
</label>
<div className="tab-content">
The nearest Airport/Railway Station is Guwahati, from Guwahati (Paltan Bazar) daily shared taxis run upto Dirang. The Guwahati-Dirang service is available only upto 0700 AM.   Some state government and private buses also run on the route please check on the internet for their availability. 
</div>
</div>
<div className="tab wow fadeInDown">
<input type="radio" id="rd11" name="rd" />
<label className="tab-label" for="rd11">11. I wanted to know that, is it necessary to post application and medical certificate alongwith relevant document via post?</label>
<div className="tab-content">
Student can submit/upload all necessary documents online. There is no need to sent
the hard copies via post, but all the requisite original documents has to be brought by the
student at time of joining.
</div>
</div>
<div className="tab wow fadeInDown">
<input type="radio" id="rd221" name="rd" />
<label className="tab-label" for="rd221">12. Where can I get the Inner Line Permit (ILP) for entry into Arunachal Pradesh?</label>
<div className="tab-content">
<p>You are requested follow the following instruction to obtain ILP</p>
<strong>FORMAT FOR INNER LINE PERMIT</strong>

<p>Google <i className="fal fa-angle-right"></i> <a href="https://arunachalilp.com/onlineApp.do" target="_blank" style={{color:'blue'}}>https://arunachalilp.com/onlineApp.do</a> <i className="fal fa-angle-right"> </i> Inner line permit management system-arunachalilp.com</p>
<img src="img/faq.jpg" alt="" />

</div>
</div> 

<div className="tab wow fadeInDown">
<input type="radio" id="rd13" name="rd" />
<label className="tab-label" for="rd13">13. What are the things we have to bring during course?</label>
<div className="tab-content">
Things which you have to bring during the courses are mentioned in Joining
Instructions.
</div>
</div>

<div className="tab wow fadeInDown">
<input type="radio" id="rd14" name="rd" />
<label className="tab-label" for="rd14">14. When are certificates issued?</label>
<div className="tab-content">Certificates are issued after one month of completion of course and send by Indian Post. 
</div>
</div>

<div className="tab wow fadeInDown">
<input type="radio" id="rd15" name="rd" />
<label className="tab-label" for="rd15">15. Can i book my seat through institute whatsapp No?</label>
<div className="tab-content">
WhatsApp is only for queries regarding the courses.  All booking related correspondence will be entertained on official email (<a href="mailto:info@nimasdirang.com">info@nimasdirang.com</a>).

</div>
</div>

<div className="tab wow fadeInDown">
<input type="radio" id="rd16" name="rd" />
<label className="tab-label" for="rd16">16. Does the course fee include contribution towards accommodation and food??</label>
<div className="tab-content">
Yes, Food, Accommodation ,Conveyance &amp; training equipment are included in fees.
</div>
</div>

<div className="tab wow fadeInDown">
<input type="radio" id="rd17" name="rd" />
<label className="tab-label" for="rd17">17. Is it compulsory to get medical form signed by Government Doctor?</label>
<div className="tab-content">
No, registered MBBS Dr can signed your medical form with relevant rubber stamp.
</div>
</div>

<div className="tab wow fadeInDown">
<input type="radio" id="rd18" name="rd" />
<label className="tab-label" for="rd18">18. Is Scuba Diving Course Certified by PADI?</label>
<div className="tab-content">
Yes in addition to NIMAS certificate the student can request for PADI certificate at an
additional cost which can be paid during the course directly to PADI (Professional
Association of Diving Instruction).
</div>
</div>

<div className="tab wow fadeInDown">
<input type="radio" id="rd19" name="rd" />
<label className="tab-label" for="rd19">19. Will I be able to fly solo in paragliding &amp; Paramotor Course?</label>
<div className="tab-content">
Yes, student will be able to fly solo in Paragliding and Paramotor Courses past
Ground Handling.
</div>
</div>

<div className="tab wow fadeInDown">
<input type="radio" id="rd20" name="rd" />
<label className="tab-label" for="rd20">20. What is the altitude of Dirang?</label>
<div className="tab-content">
Institute’s altitude is 6750 ft.
</div>
</div>

<div className="tab wow fadeInDown">
<input type="radio" id="rd21" name="rd" />
<label className="tab-label" for="rd21">21. Would I be able to work along with the course?</label>
<div className="tab-content">
Training Scheduled start at 06:00 Am and is active till 18:00 PM. Student can engage
according to their comfort during the remaining hour. Although it is not recommended as the
course requires high degree of physical commitment.
</div>
</div>

<div className="tab wow fadeInDown">
<input type="radio" id="rd22" name="rd" />
<label className="tab-label" for="rd22">22. What will be the time schedule during the course?</label>
<div className="tab-content">
You will be instructed on time schedule during the course by the Senior Instructor on
daily basis during the course. This schedule may vary depending upon the nature of event
planned for that day.
</div>
</div>

<div className="tab wow fadeInDown">
<input type="radio" id="rd23" name="rd" />
<label className="tab-label" for="rd23">23. Can I get single accommodation during the course?</label>
<div className="tab-content">
No, Single accommodation is not available during the course.
</div>
</div>

<div className="tab wow fadeInDown">
<input type="radio" id="rd24" name="rd" />
<label className="tab-label" for="rd24">24. When will be the refund receive in case of cancellation?</label>
<div className="tab-content">
Refund is only processed post completion of the course subscribed.  Also refer to the refund policy of this institute available at the website. 
</div>
</div>




</div>
</div>
</div>
</div>
</div>
          </div>

          <Footer />
              
        </>
  );
}

export default Faq;
