import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Link, Routes, Navigate, Switch, HashRouter} from 'react-router-dom';
import About from './Components/About';
import Contact from './Components/Contact';
import Story from './Components/Story';
import Aim from './Components/Aim';
import Campus from './Components/Campus';
import Organisation from './Components/Organisation';
import Faq from './Components/Faq';
import PrivacyPolicy from './Components/PrivacyPolicy';
import Terms from './Components/Terms';
import Downloads from './Components/Downloads';
import News from './Components/News';
import Home2 from './Components/Home2';
import Mountaineeringdetails from './Components/Courses/Mountaineeringdetails';
import Mtbdetails from './Components/Courses/Mtbdetails';
import Scubadivingdetails from './Components/Courses/Scubadivingdetails';
import Raftingdetails from './Components/Courses/Raftingdetails';
import Paraglidingdetails from './Components/Courses/Paraglidingdetails';
import Paramotordetails from './Components/Courses/Paramotordetails';

import Login from './student-login/Login';
import Register from './student-login/Register';
import Bookcourse from './student-login/Bookcourse';
import Payment from './student-login/Payment';
import Thankyou from './student-login/Thankyou';
import Booking from './student-login/Booking';
import Invoice from './student-login/Invoice';
import Myprofile from './student-login/Myprofile';
import Bookingdetails from './student-login/Bookingdetails';
import Dashboard from './student-login/Dashboard';
import NimasApplication from './student-login/NimasApplication';
import Invoicenew from './student-login/Invoicenew';

import NimasLogin from './nimas-admin/NimasLogin';
import NimasRegister from './nimas-admin/NimasRegister';
import NimasDashboard from './nimas-admin/NimasDashboard';
import NimasBooking from './nimas-admin/NimasBooking';
import Confirmbooking from './nimas-admin/Confirmbooking';
import InreviewBookings from './nimas-admin/InreviewBookings';
import DocumentPending from './nimas-admin/DocumentPending';
import NimasBookingdetails from './nimas-admin/NimasBookingdetails';
import NimasInvoice from './nimas-admin/NimasInvoice';
import NimasadminProfile from './nimas-admin/NimasadminProfile';
import Cancelbooking from './nimas-admin/Cancelbooking';
import StudentDetails from './nimas-admin/StudentDetails';
import Home from './Components/Home';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <Routes>
      <Route path="/" element={<Home />} /> 
      <Route path="/about-us" element={<About />} /> 
      <Route path="/contact-us" element={<Contact />} /> 
      <Route path="/story" element={<Story />} /> 
      <Route path="/aim" element={<Aim />} /> 
      <Route path="/campus" element={<Campus />} /> 
      <Route path="/organisation" element={<Organisation />} /> 
      <Route path="/faq" element={<Faq />} />  
      <Route path="/privacy-policy" element={<PrivacyPolicy />} /> 
      <Route path="/terms-and-conditions" element={<Terms />} /> 
      <Route path="/downloads" element={<Downloads />} /> 
      <Route path="/news-and-events" element={<News />} /> 
      <Route path="/Mountaineering-courses" element={<Mountaineeringdetails />} />      
      <Route path="mtb-courses" element={< Mtbdetails />} />
      <Route path='/booking' element={<Booking />} />
      <Route path='/scuba-diving-courses' element={<Scubadivingdetails />} />
      
      <Route path='/rafting-courses' element={<Raftingdetails />} />
      <Route path='/paragliding-courses' element={<Paraglidingdetails />} />
      <Route path='/paramotor-courses' element={<Paramotordetails />} />
      
      <Route path='/student-admin/login' element={<Login />} />
      <Route path='/student-admin/register' element={<Register />} />
      <Route path='/student-admin/book-course' element={<Bookcourse />} />
      <Route path='/student-admin/payment' element={<Payment />} />
      <Route path='/student-admin/thankyou' element={<Thankyou />} />
      <Route path='/student-admin/booking' element={<Booking />} />
      <Route path='/student-admin/invoice' element={<Invoice />} />
      <Route path='/student-admin/myprofile' element={<Myprofile />} />
      <Route path='/student-admin/bookingdetails' element={<Bookingdetails />} />
      <Route path='/student-admin/dashboard' element={<Dashboard />} />
      <Route path='/student-admin/application-form' element={<NimasApplication />} />
      <Route path='/student-admin/invoicenew' element={<Invoicenew />} />

      
      <Route path='/nimas-admin/login' element={<NimasLogin />} />
      <Route path='/nimas-admin/register' element={<NimasRegister />} />
      <Route path='/nimas-admin/dashboard' element={<NimasDashboard />} />
      <Route path='/nimas-admin/bookings' element={<NimasBooking />} />
      <Route path='/nimas-admin/confirmbookings' element={<Confirmbooking />} />
      <Route path='/nimas-admin/in-review-bookings' element={<InreviewBookings />} />
      <Route path='/nimas-admin/document-Pending' element={<DocumentPending />} />
      <Route path='/nimas-admin/bookingdetails' element={<NimasBookingdetails />} />
      <Route path='/nimas-admin/invoice' element={<NimasInvoice />} />
      <Route path='/nimas-admin/myprofile' element={<NimasadminProfile />} />
      <Route path='/nimas-admin/cancel-bookings' element={<Cancelbooking />} />
      <Route path='/nimas-admin/student-info' element={<StudentDetails />} />
      <Route path='/home' element={<Home2 />} />
      
      <Route path="/*" element={<Navigate to = "/" />} />
      
  </Routes>
</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
