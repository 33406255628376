import React, { useEffect, useState } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";

function Contact() {
  let authToken =
    "oq+R3B3lt5I1bybDbHvscBRQDkBF8ek9cciW21c2Lza9XNAKeGE13bKO1giqxZ/uL2fM+m2QLjbSkMpBo3qM9DqDqDv7SojZA+5WpHVMh+tsnC9HUXan4CKJ8/bI4/2Lqa7DMzLXd1LqL9KA1iuY8g==";
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Message, setMessage] = useState("");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [error, setError] = useState("");
  const ContactPara = {
    templateID: "8",
    _1: FirstName + " " + LastName,
    _2: Email,
    _3: Mobile,
    _4: Message,
  };
  function funContact() {
    if (FirstName.trim() === "") {
      setError("Please enter first name.");
      document.getElementById("txtFirstName").focus();
      return;
    }
    if (LastName.trim() === "") {
      setError("Please enter last name.");
      document.getElementById("txtLastName").focus();
      return;
    }
    if (Email.trim() === "") {
      setError("Please enter email id.");
      document.getElementById("txtEmail").focus();
      return;
    } else if (!emailRegex.test(Email.trim())) {
      setError("Please enter valid email id.");
      document.getElementById("txtEmail").focus();
      return;
    } else if (Mobile.trim() === "") {
      setError('Please enter mobile no."');
      document.getElementById("txtmobile").focus();
      return;
    } else if (Message.trim() === "") {
      setError('Please enter your message"');
      document.getElementById("txtmessage").focus();
      return;
    }

    fetch("https://api.dialerp.com/api/api/addUpdateTemplateDataWeb", {
      method: "POST",
      headers: {
        "Authorization-Token": authToken,
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(ContactPara),
    }).then((result) => {
      result.json().then((resp) => {
        debugger;
        if (resp.hasErrors === false) {
          alert("Data save successfully.");
          window.location.reload(true);
        } else {
          alert(resp.errors);
        }
      });
    });
  }
  const handleChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    setMobile(value);
  };
  const fun_Contact = async () => {
    debugger;
    if (FirstName.trim() === "") {
      setError("Please enter name.");
      document.getElementById("txtFirstName").focus();
      return;
    } else if (Email.trim() === "") {
      setError("Please enter email id.");
      document.getElementById("txtEmail").focus();
      return;
    } else if (!emailRegex.test(Email.trim())) {
      setError("Please enter valid email id.");
      document.getElementById("txtEmail").focus();
      return;
    } else if (Mobile.trim() === "") {
      setError('Please enter mobile no."');
      document.getElementById("txtmobile").focus();
      return;
    } else if (Mobile.length != 10) {
      alert("Please enter a valid 10-digit mobile number"); // Set error message if invalid
      document.getElementById("txtmobile").focus();
      return;
    } else if (Message.trim() === "") {
      setError('Please enter your message"');
      document.getElementById("txtmessage").focus();
      return;
    }
    debugger;
    try {
      const response = await fetch(
        "https://projects.erpthemes.com/api/dynamic/addRecordsDynamic?tempID=29&tempName=nimas_contact_us",
        {
          method: "POST",
          headers: {
          "Content-Type": "application/json",
        "authorization": "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NzJiMTAwODlmNjA4NDEwZmI1NDA3MWMiLCJzZXNzaW9uIjoiNjcyYjEwMDg5ZjYwODQxMGZiNTQwNzFlIiwibmFtZSI6Im5pbWFzZGlyYW5nIiwiZW1haWwiOiJpbmZvQG5pbWFzZGlyYW5nLmNvbSIsInVzZXJUeXBlIjoidXNlciIsInVzZXJJZCI6IjY3MmIxMDA4OWY2MDg0MTBmYjU0MDcxYyIsImlhdCI6MTczMDg3NTQwMCwiZXhwIjoxODg4NjYzNDAwfQ.mDeBeI33GEimvCNZ7vf_fi-tHRJPuvFTm9AHaI11eCqVp3dvl1yo42SBWioQlJJctJ5C0Q3ho8JTkKnPXeqIdaphhkgcQGSrIBWqUSloqsOVPm0RTwfSncMvLwSnDg7DcRTWJNEno8uDOXNQIsfy67BY6UiFD3WW_8aBgeaQeDCOeSMmRrdYPVyE53reVAOewLJF6pcYTRKl1PRhGTcOOhpT7lXJCudAU7ABfBnGxGPrVDcW0X7wni81_ICZjn4wh6UoYRn7sEiGm646yBh0v9GEry7tkY744oLBynC4Tg3nHpASYQyv8amGiOBzmWQ7kHIa6Bah4mezZcIqHzaJ0Q",
        "Accept": "application/json",
        "Accept-Language": "en",
          },
          body: JSON.stringify({
            query: Message,
            name: FirstName + " " + LastName,
            email: Email,
            mobile: Mobile,
            domain: "nimasdirang.com",
          }),
        }
      );

      if (!response.ok) {
        throw new Error(
          `Network response was not ok: ${response.statusText}`
        );
      }
      console.log(response);
      const result = await response.json();
      if (result) {
        alert(result.resp.message);
        window.scrollTo(0, 0);
      } else {
        console.log(result)
        alert("Please try again later.");
      }
    } catch (error) {
      alert(error?.Message);
      console.error("Error:", error);
    }
  };
  return (
    <>
      <Header />
      <div className="wrapper">
        <div className="bradcumb-area style-contact">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col">
                {/* <div className="bradcumb text-center">
                  <h3>Contact Us</h3>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="contact-area pt-90 pb-95">
          <div className="container">
            <div className="row justify-content-center wow fadeInDown">
              <div className="col-lg-10">
                <div className="section-title text-center">
                  <p className="title">Contact Information</p>
                  <h2>
                    We Would Love To<span></span> hear From You
                  </h2>
                </div>
              </div>
            </div>
            <div className="row wow fadeInDown contactinfo">
              <div className="col-lg-4 col-sm-4">
                <div className="single-contact text-center">
                  <div className="contact-thumb">
                    <img src="img/icon/c1.png" alt="" />
                  </div>
                  <div className="contact-content">
                    <h4>Our Location</h4>
                    <p>
                      National Institute of Mountaineering and Adventure Sports,
                      Dirang, West Kameng, Arunachal Pradesh, Pin- 790101
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-sm-4">
                <div className="single-contact text-center">
                  <div className="contact-thumb">
                    <img src="img/icon/c3.png" alt="" />
                  </div>
                  <div className="contact-content">
                    <h4>Phone</h4>
                    <p>
                      Course Related Queries:{" "}
                      <a href="tel:+91-9774731855">+91-9774731855</a>
                    </p>
                    <p>
                      Booking Related Queries:{" "}
                      <a href="tel:+91-7640002112">+91-7640002112</a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-4">
                <div className="single-contact text-center">
                  <div className="contact-thumb">
                    <img src="img/icon/c4.png" alt="" />
                  </div>
                  <div className="contact-content">
                    <h4>
                      Email(Course booking and fee/refund related queries)
                    </h4>
                    <a href="info@nimasdirang.com">info@nimasdirang.com</a>
                    <a href="mailto:nimas.trg@gmail.com">nimas.trg@gmail.com</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="google-map-area">
          <div className="container">
            <div className="row">
              <div className="col-sm-6 wow fadeInLeft">
                <div className="pt-90">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3543.141672463844!2d92.22870091505479!3d27.371290582932275!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x375cbb4706bbdb73%3A0x9a0a79657e05c2!2sNational%20Institute%20of%20Mountaineering%20and%20Adventure%20Sports%20(NIMAS)!5e0!3m2!1sen!2sin!4v1676463577652!5m2!1sen!2sin"
                    width="100%"
                    height="310"
                    frameBorder="1"
                    style={{ border: 1 }}
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                  />
                </div>
              </div>
              <div className="col-sm-6 wow fadeInRight">
                <div className="section-title pt-90">
                  <p className="title">Get In Touch</p>
                  <h3>
                    Drop Us a Line. We'd love<span></span> to hear from you!
                  </h3>
                </div>

                <div className="contact-p-contact pb-70">
                  {/* <form className="gane-form"> */}

                  <div class="single-field half-field sf">
                    <input
                      type="text"
                      value={FirstName}
                      className={`form-control ${
                        error && FirstName.trim() === "" ? "is-invalid" : ""
                      }`}
                      onChange={(e) => setFirstName(e.target.value)}
                      id="txtFirstName"
                      placeholder="First Name"
                    />
                    {error && FirstName.trim() === "" && (
                      <div className="invalid-feedback">
                        Please enter first name.
                      </div>
                    )}
                  </div>

                  <div className="single-field half-field-last">
                    <input
                      type="text"
                      value={LastName}
                      className={`form-control ${
                        error && LastName.trim() === "" ? "is-invalid" : ""
                      }`}
                      onChange={(e) => setLastName(e.target.value)}
                      id="txtLastName"
                      placeholder="Last Name"
                    />
                    {error && LastName.trim() === "" && (
                      <div className="invalid-feedback">
                        Please enter last name.
                      </div>
                    )}
                  </div>

                  <div className="single-field half-field">
                    <input
                      type="email"
                      value={Email}
                      className={`form-control ${
                        error && Email.trim() === "" ? "is-invalid" : ""
                      }`}
                      onChange={(e) => setEmail(e.target.value)}
                      id="txtEmail"
                      placeholder="Your Email"
                    />
                    {error && Email.trim() === "" && (
                      <div className="invalid-feedback">
                        Please enter email id.
                      </div>
                    )}
                  </div>

                  <div className="single-field half-field-last">
                    <input
                      type="tel"
                      value={Mobile}
                      className={`form-control ${
                        error && Mobile.trim() === "" ? "is-invalid" : ""
                      }`}
                      //   onChange={(e) => setMobile(e.target.value)}
                      onChange={handleChange}
                      id="txtmobile"
                      placeholder="Mobile"
                      maxLength={10}
                    />
                    {error && Mobile.trim() === "" && (
                      <div className="invalid-feedback">
                        {" "}
                        Please enter mobile no.
                      </div>
                    )}
                  </div>
                  <div className="single-field textf">
                    <textarea
                      value={Message}
                      className={`form-control ${
                        error && Message.trim() === "" ? "is-invalid" : ""
                      }`}
                      onChange={(e) => setMessage(e.target.value)}
                      rows="3"
                      id="txtmessage"
                      placeholder="Enter your message"
                    ></textarea>
                    {error && Message.trim() === "" && (
                      <div className="invalid-feedback">
                        Please enter your message.
                      </div>
                    )}
                  </div>
                  <button
                    className="btn btn-theme"
                    type="submit"
                    onClick={() => fun_Contact()}
                  >
                    Send Message
                  </button>

                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Contact;
